h1 {
  display: flex;
  justify-content: center;
  color:#eae0c2
}

h3 {
  display: flex;
  justify-content: center;
  color:#eae0c2
}

a {
  color: #dfc2ea
}

.btn-div {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 10px;
}

.btn {
	box-shadow: 0px 1px 0px 0px #1c1b18;
	/* background:linear-gradient(to bottom, #d5c2ea 5%, #b58fc7 100%); */
	/* background-color:#8790e3; */
	border-radius:15px;
	border:2px solid #333029;
	display:inline-block;
	cursor:pointer;
	color:#000000;
	font-family:Arial;
	font-size:14px;
	font-weight:bold;
	padding:12px 16px;
	text-decoration:none;
	text-shadow:0px 1px 0px #eeeeee;
  margin-left: 2%;
}

.btn:hover {
	background:linear-gradient(to bottom, #baa4c4 5%, #b58fc7 100%);
	background-color:#ccc2a6;
}
.btn:active {
	position:relative;
	top:1px;
}

.chart-div {
  background-color: #ffffff;
  margin-left: 15%;
  margin-right: 15%;
}

.theory-div {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  color:#eae0c2
}

/*********** Baseline, reset styles ***********/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 25rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #492350;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -4px; /* Centers thumb on the track */
  background-color: #5a5858;
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
  outline: 3px solid #5a5858;
  outline-offset: 0.125rem;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 20px;
  animate: 0.2s;
  background-color: #492350;
  border-radius: 0.5rem;
  height: 0.5rem;
}

input[type=range]::-moz-range-thumb {
  outline-offset: 0.125rem;
}

.description-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color:#e6e6e6;
  margin-left: 7%;
  margin-right: 7%;
  margin-top: 3%;
}

.slider-green::-webkit-slider-runnable-track {
  background-color: #42ac5b !important;
}

.slider-green::-moz-range-track {
  background-color: #42ac5b !important;
}

.slider-blue::-webkit-slider-runnable-track {
  background-color: #4259ac !important;
}

.slider-blue::-moz-range-track {
  background-color: #4259ac !important;
}

.slider-red::-webkit-slider-runnable-track {
  background-color: #a42020 !important;
}

.slider-red::-moz-range-track {
  background-color: #a42020 !important;
}

.text-div {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  color:#e6e6e6;
  margin-left: 7.5%;
  margin-right: 7.5%;
}